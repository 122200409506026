<template>
  <div class="columns">
    <Kategori />
    <div class="section column" id="borderKonten" v-for="(info, i) in infos" :key="i.idKategori">
      <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
        <ul>
          <li><a href="/">Home</a></li>
          <li><router-link :to="`/kategori/`+info.idKategori">{{info.namaKategori}}</router-link></li>
          <li class="is-active"><a href="#" aria-current="page">{{info.namaSub}}</a></li>
        </ul>
      </nav>
      <div id="divKonten" class="title is-4"><span id="judulKonten">{{info.namaSub}}</span></div>
        <div>
          <p id="judulDetail" class="title">Objek {{info.namaSub}}</p>
          <!-- <p id="isiDetail">{{info.deskripsi}}</p> -->
          <div id="objectCards" class="columns is-multiline">
            <div class="column is-4-tablet is-3-desktop" v-for="(item, i) in items" :key="i.Kategori">
              <div class="container">
                  <div id="cardContent" class="bg">
                      <img id="image" src="@/assets/bgCard.png">
                      <h2 class="judul">{{item.Kategori.replaceAll("\"", "")}}</h2>
                      <div class="overlay">
                          <div class="bot">
                          <router-link id="seeMore" :to="`/kategori/subkategori/listobjek/`+item.Label"><span >See More</span></router-link>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <p id="judulDetail" class="title"><br> Artikel</p>
          <div>
              <div>
                <Artikel />
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Kategori from './Kategori.vue'
import axios from "axios"

export default {
    name: "Objek",
    components : {
        Kategori
    },
    data(){
        return{
          infos:[],
          items:[],
        };
    },
    created() {
        this.getKategoriJoinedSub();
        this.getObjekById();
    },
    methods: {
        async getObjekById() {
          try {
              if(this.$route.params.id == "Candi%20Nonkeagamaan") {
                const paramsParsed = this.$route.params.id.replace("%20", "") 
                const resp = await axios.get(`https://portalapi.alunalun.info/kategoriCandi/${paramsParsed}`)
                this.items = resp.data
              }
          } catch (err) {
              console.log(err);
          }
        },  
        async getKategoriJoinedSub() {
          try {
              const response = await axios.get(`https://portalapi.alunalun.info/kategorisub/${this.$route.params.id}`);
              this.infos = response.data;
          } catch (err) {
              console.log(err);
          }
        },
    },
}
</script>